import React from "react"
import { Row, Col } from "antd"
import AboutTile from "../../AbouTile"
import { stripTags, domHtml } from "../../../utils/stripTags"
import SEO from "../../Seo"

const pageText = {
  paraOne:
    "Hello! My name is Stuart, and I’m a software engineer. After working professionally as an audio engineer and mechanical engineer, I decided to refocus my passion for engineering to software, specifically web technologies.",
  paraTwo:
    "As a mechanical engineer I spent a lot of time coding in Visual Basic for Applications (VBA) and began learning Python. I loved coding so much that I began spending all my free time learning and building small web applications. Ultimately, I decided to quit my job and pursue software engineering. Once I felt proficient building fullstack JavaScript applications with React and PostgreSQL, I began working for an NYC based startup, Winit. My time at Winit gave me a much broader knowledge of technologies, honed my ability to write clean code, and enhanced my capacity to solve difficult technical problems.",
  paraThree:
    "Currently, I am learning Amazon Web Services and TypeScript while building React.js websites for a few clients. If you are a small business looking for a new high-performance website or a large corporation looking for a passionate developer, I’d love to hear from you!",
}
const AboutMe = () => {
  const description = `${pageText.paraOne} ${stripTags(pageText.paraTwo)}`
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={[
            "Stuart",
            "Jehn",
            "Frontend developer",
            "FullStack developer",
            "Javascript",
            "ReactJS",
            "NodeJS",
            "Gatsby",
            "Next",
          ]}
        />
        <h1 className="titleSeparate">About Me</h1>
        <p>{pageText.paraOne}</p>
        {/* <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.paraThree)} /> */}
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="programmer.png"
            height={60}
            alt="programmer image"
            textH4="Code for Life"
            textH3="Always coding something"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="tea.png"
            alt="tea image"
            textH4="Love Tea"
            textH3="Tea + Me = Happiness"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="chess.png"
            alt="chess image"
            textH4="Chess with Friends"
            textH3="Trying to find the best move"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="climbing.png"
            alt="climbing image"
            textH4="Love the Outdoors"
            textH3="The mountains call me"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="audio.png"
            alt="audio engineer image"
            textH4="Audio Nerd"
            textH3="Can't live without music"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduate.png"
            alt="graduation image"
            textH4="Lifelong Learner"
            textH3="Always learning something"
            height={60}
            width={60}
          />
        </Col>
      </Row>
    </>
  )
}
export default AboutMe
